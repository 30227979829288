@import '/src/storybook/styles/variables';
@import '/src/storybook/styles/mixins';

.results-pane {
  width: calc(100% - 342px);
  .results-counter {
    z-index: 1;
    position: sticky;
    top: 0px;
    height: 36px;
    background-color: $component-background;
    padding: 34px 35px 0px;
    .counter {
      color: $secondary-text-color;
    }
  }
  .results-counter {
    z-index: 3;
    display: flex;
    justify-content: space-between;
  }
  .export-row {
    display: flex;
    padding: 0px 35px;
    .export-dropdown-holder {
      width: 100px;
    }
    .export-dropdown {
      z-index: 2;
    }
    .clear-export {
      align-self: center;
      margin-left: -64px;
      cursor: pointer;
      color: $secondary-text-color;
      z-index: 3;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .results-row {
    display: grid;
    //grid-template-columns: 50px 130px minmax(150px, 1fr) minmax(72px, 1fr) 100px minmax(88px, 1fr) 1fr 1fr minmax(140px, 1fr) minmax(140px, 1fr) 80px;
    grid-template-columns: 50px 130px minmax(150px, 1fr) minmax(72px, 1fr) 100px minmax(88px, 1fr) 1fr 1fr minmax(140px, 1fr) 80px;
    &.for-partner {
      grid-template-columns: 50px 130px minmax(150px, 1fr) minmax(72px, 1fr) 100px minmax(88px, 1fr) 1fr 1fr minmax(140px, 1fr) 80px;
    }
    &.results-header {
      z-index: 1;
      position: sticky;
      top: 68px;
      background-color: $component-background;
      padding-bottom: 10px;
      margin: 20px 35px 0px;
      .servers-enclosures {
        margin-left: 30px;
        margin-right: 40px;
      }
    }
    > .center {
      justify-self: center;
    }
    .copy {
      cursor: pointer;
    }
  }
  .error {
    color: red;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px 40px;
    font-size: 18px;
    > div {
      margin-top: 10px;
    }
  }

  &.calculating {
    @include disabled-component;
  }
}

.export-dropdown-tooltip.rc-tooltip .rc-tooltip-inner {
  color: $secondary-text-color;
  padding: 14px;
  width: auto;
  min-height: auto;
}